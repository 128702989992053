<template>
  <div class="layer-page-div">
    <div class="item-content-div">
      <div class="item-div">
        <div class="item-name-div">分类名称：</div>
        <div class="item-value-div">
          <label>
            <input v-model="typeName" type="text" maxlength="10" placeholder="请输入分类名称">
          </label>
        </div>
      </div>
      <div class="operate-div">
        <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
        <div class="text-c">
          <input type="button" value="取消" @click="onCancel">
          <input class="save-input" type="button" value="保存" @click="onSave">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { postManufacturerTypeEdit } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    typeMsg: {
      type: Object,
      default: () => {}
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      typeName: this.typeMsg.name,
      tipMsg: ""
    }
  },
  methods: {
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onSave() {
      if(this.typeName.length < 1){
        this.setTipsMsg("请输入分类名称")
        return;
      }
      if(this.typeName.length > 10){
        this.setTipsMsg("分类名称不能超过20个字符")
        return;
      }

      let typeName = this.typeName.trim();
      if(typeName === this.typeMsg.name){
        this.refreshData();
        this.$layer.close(this.layerid);
        this.$layer.msg("操作成功");
        return;
      }

      postManufacturerTypeEdit({id: this.typeMsg.id, name: typeName})
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .item-content-div {
    width: 90%;
    margin: 20px auto 0 auto;
    .item-div {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .item-name-div {
        width: 100px;
        text-align: right;
      }
      .item-value-div {
        input {
          width: 288px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
      }
    }
    .operate-div {
      margin-top: 20px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
        margin-top: 10px;
      }
    }
  }
}
</style>
import { render, staticRenderFns } from "./ManufacturerTypes.vue?vue&type=template&id=24b9d766&scoped=true&"
import script from "./ManufacturerTypes.vue?vue&type=script&lang=js&"
export * from "./ManufacturerTypes.vue?vue&type=script&lang=js&"
import style0 from "./ManufacturerTypes.vue?vue&type=style&index=0&id=24b9d766&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24b9d766",
  null
  
)

export default component.exports